@import '../../../../index.scss';

@media screen and (min-width:700px) {
    .recruitmenter .header {
        display: block;
    }
}
@media screen and (min-width:1200px) {
    .recruitmenter .header {
        display: flex;
    }
}
.recruitmenter {
    // max-width: 1440px;
    // margin: auto;
    // display: grid; 
    // grid-template-columns: 0.5fr 2fr 0.5fr; 
    // grid-template-rows: auto 1fr auto auto; 
    // gap: 0px 0px; 
    // grid-template-areas: 
    //   "steps steps avatar"
    //   "body body body"
    //   "back techs next"
    //   "footer footer footer"; 
    height: 100% !important;
    display: flex !important;
    flex-flow: column;
    direction: ltr;
    text-align: left;
    box-sizing: content-box !important;
}
.recruitmenter .steps{
    display: grid;
    background-color: $primary-color4;
    padding: 16px;
}
.recruitmenter .steps> div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.recruitmenter .avatars{
    display: flex;
    flex-direction: row;
    justify-content: end;
    background-color: $primary-color4;
    padding:16px;
}
.recruitmenter .avatars div {
    width: 56px;
    height: 56px;
}
.recruitmenter .avatars div:first-child {
    margin-right: -8px;
}
.recruitmenter .header{
    flex: 0 1 auto;
}

.recruitmenter .body{
    flex: 1 1 auto;
    background-color:aliceblue;
    color: $primary-color2;
}
.recruitmenter .body > div{
    display: flex;
    flex-direction: column;
}
.recruitmenter .body > div > article{
    flex: 1 1 auto;
    max-width: 1000px;
    margin:auto;
}
.recruitmenter .body > div > div{
    flex: 0 1 auto;
}
.recruitmenter .footer{
    flex: 0 1 auto;
    min-height: 60px;
    background-color: $primary-color4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    margin-top: auto;
    margin-bottom: auto;
    // grid-area: footer;
}
.recruitmenter .footer  div:nth-child(2) {
    font-family: 'Tangerine';
}
 
.recruitmenter 
h1 { 
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
} 
.recruitmenter 
h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
} 
.recruitmenter 
h3 { 
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
} 
.recruitmenter 
h4 { 
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
} 
.recruitmenter 
h5 { 
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
} 
.recruitmenter 
h6 { 
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
} 
