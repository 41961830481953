@import '../../../../index.scss';

.recruitmenter .needs{
    // max-width: 1200px;
    // margin:auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 100px;
}

.recruitmenter .needs section{
    max-width: 400px;
}
.recruitmenter .needs h1{
    font-size: xx-large;
}
#needs_answer{ 
    font-family: "Merriweather";
    max-width: 400px;
    background-color: #00685f;
    border-radius: 8px;
    padding: 16px;
    color: #c9c256;
    display: flex;
}
#needs_answer.deactive{ 
    display: none;
}