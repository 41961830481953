#home h1 {
    font-size: 40px;
}

#home {
    font: 20px Helvetica, sans-serif;
    color: #333;
    display: block;
    text-align: left;
    width: 650px;
    margin: 0 auto;
}

#home a {
    color: #dc8100;
    text-decoration: none;
}

#home a:hover {
    color: #333;
    text-decoration: none;
}








#home2{
    background-color: #d1d1d1;
    height: 100%;
    position: relative;
    z-index: 0;
}
#home2 section{
    min-height: 100%;
    padding: 16px;
    padding-top: 90px;
    background-color: #000c2e;
    h1{
        color: white;
        div{
            color: white;
            background-color: #000C2E;
            padding: 8px;
        }
    }
}
#home2 *{

    box-sizing: border-box;
}

#home2 header{

    height: 90px;
    position: fixed;
    margin: 0px;
    top: 0px;
    width: 100%;
    z-index: 100;
    background-color: rgb(136, 136, 136); /* Fallback color */
    background-color: rgba(136, 136, 136, 0.4); /* Black w/opacity/see-through */
    backdrop-filter: blur(10px);
}
#home2 header .avatar{
    width: 5px;
    height: 5px;
    margin: auto;
    top:50%;
    margin-right: 8px;
}
#home2 header a:link{
    color: #fb6020;
    text-decoration: none;
    position: relative;
    top: -2px;
}
#home2 header svg{
    color: #b31fbb;
    margin-bottom: 0px;
}
#home2 header .MuiListItemText-primary{
    color: #dadada;
}
#home2 header .MuiListItemText-secondary{
    color: #a0a0a0;
}
#home2 ul li u{
    color: #ffffff;
}
#home2 #greating .bg{
    // background-color: #1e87f0;
    background-image: url("../img/greating-bg.jpg");
    
  /* Add the blur effect */
  filter: blur(1px);
  -webkit-filter: blur(1px);

  /* Full height */
  height: 100%;
  width: 100%;
  position: absolute;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top:0;
//   z-index: -1;
}
#home2 #greating .content{
    
    color: white;
    width: 80%;
    padding: 20px;
    margin-right: auto;
    margin-left: auto;
    
}

#home2 #greating .content .text{

    background-color: rgb(136, 136, 136); /* Fallback color */
    background-color: rgba(136, 136, 136, 0.4); /* Black w/opacity/see-through */
    backdrop-filter: blur(10px);
}
#home2 #greating .content .text #greating-text{
    width: 100%;
    word-wrap: break-word;
    white-space: pre-line;
}

#home2 #greating .content .category{

    background-color: rgb(136, 136, 136); /* Fallback color */
    background-color: rgba(136, 136, 136, 0.4); /* Black w/opacity/see-through */
    backdrop-filter: blur(20px);
}
#greating .content .MuiAvatar-circular{
    background: rgb(69,79,232);
    background: linear-gradient(90deg, rgba(69,79,232,1) 0%, rgba(183,32,191,1) 23%, rgba(253,94,29,1) 80%); 
}
#greating .content .MuiListItemText-secondary{
    color: #3db378;
}
// #home2 #greating li{

// backdrop-filter: brightness(60%);
// backdrop-filter: contrast(40%);
// backdrop-filter: drop-shadow(4px 4px 10px blue);
// }
#home2 #services{
    background: linear-gradient(45deg, #000C2E 0%, #016D85 100%);
}
#home2 #portfolio{
    background: linear-gradient(90deg, rgb(0 7 108) 0%, rgb(67 0 70) 70%);
    .techs::-webkit-scrollbar {
        width: 1em;
    }
    
    .techs::-webkit-scrollbar-thumb {
        background-color: #818B99;
    border: 6px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
      }
    .owl-carousel .owl-item img {
        height: 100%;
        max-height: 200px;
    }
    .techs{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        // -ms-overflow-style: none;  /* IE and Edge */
        // scrollbar-width: none;  /* Firefox */
        img{
            margin: 8px;
            width: 125px;
            height: 125px;
        }
    }
}
#home2 #music{
    >div>div{
        margin-top: 30px !important;
    }
    background: linear-gradient(90deg, rgb(44, 44, 44) 0%, rgb(179 28 0) 70%);
}
#home2 .animate-in{
    margin-left: -500px;
}