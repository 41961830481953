@import '../../../../index.scss';

.recruitmenter .step-control{
    margin: 16px;
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.recruitmenter .step-control .back{
    display: flex;
    flex-direction: row;
    justify-content: end;
    // background-color: $primary-color5;
}

.recruitmenter .step-control .next{
    display: flex;
    flex-direction: row;
    justify-content: start;
    // background-color: $primary-color5;
}
.recruitmenter .step-control > div > *{
    margin: 4px;
    margin-top: auto;
    margin-bottom: auto;
}
.recruitmenter .step-control button{
    color: $primary-color1;
    background-color: $primary-color3;
}
