@import '../../../../index.scss';

.recruitmenter .questions{
    margin-top: 40px;
}
.recruitmenter .questions label{
    padding: 16px;
    font-style: oblique;
    font-size: medium;
    min-height: 80px;
}
.recruitmenter .questions button[type="submit"]{
   margin: auto;
   display: flex;
}
.recruitmenter .questions h3 {
    margin: auto;
    width: fit-content;
    color: $primary-color3;
}