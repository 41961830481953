html{
  height: 100%;
  
}
body {
  background-color: rgba($color: #d1d1d1, $alpha: 1.0);
    height: 100% !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body, html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  z-index: -10px;
}
#root{
    height: 100% !important;
    z-index: -3;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


$primary-color1 : rgba(12, 54, 64, 1);
$primary-color2 : rgba(24, 83, 89, 1);
$primary-color3 : rgba(43, 131, 140, 1);
$primary-color4 : rgba(96, 191, 191, 1);
$primary-color5 : rgba(139, 217, 217, 1);

