@import '../../../../index.scss';

.recruitmenter .techs{
    padding: 32px;
    font-family: 'Merriweather' ;
    font-size: x-large;
    text-align: justify;
    font-weight: bold;
    line-height: 1.6;
}
