@import '../../../../index.scss';

.recruitmenter .words{
    padding: 32px;
    font-family: 'Merriweather' ;
    font-size: large;
    text-align: justify;
    font-weight: bold;
    line-height: 1.6;
}
.recruitmenter .words a:link{
    text-decoration: none;
    color: $primary-color3;
    font-family: "Merriweather";
}