@import '../../../../index.scss';

.recruitmenter .greeting{

    display: grid;
    grid-template-columns: 1f1 1fr;
    grid-template-rows: 1fr 4fr 2fr;
    grid-template-areas: 'hi hi'
                         'quot pic'
                         'intro intro';
}

.recruitmenter .greeting .hi{
    grid-area: hi;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.recruitmenter .greeting .hi > *{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
}
.recruitmenter .greeting .quot{
    grid-area: quot;
}
.recruitmenter .greeting .pic{
    grid-area: pic;
}
.recruitmenter .greeting .intro{
    grid-area: intro;
}
.recruitmenter .greeting h1{
    font-size: xx-large;
}
.recruitmenter .greeting img{
    max-width: 100%;
    max-height: 400px;
}